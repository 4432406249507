import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { t } from 'i18next';
const ShowDateTime = () => {
  const [datetime, setDatetime] = useState(moment().format('DD/MM/YYYY HH:mm:ss'));

  useEffect(() => {
    const a = setInterval(() => {
      setDatetime(moment().format('DD/MM/YYYY HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(a);
    };
  }, []);

  return (
    <div className='flex ml-0 md:ml-4 bg-[#011021] items-center justify-center md:justify-start  h-12'>
      <img className='hidden md:block w-10' src={require('../assets/images/loading.gif')} alt='time' />
      <span className='text-white text-sm font-bold mr-1 hidden md:block'>{t('time')}: </span>
      <span className='text-white text-sm'>{datetime}</span>
    </div>
  );
};

export default ShowDateTime;
