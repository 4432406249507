import { Button, Card, Form, Input, message, Modal, Select, Space, Statistic, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentData, getBetData, updateByAgent } from '../features/user/userSlice';
import { formatMoney } from '../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { unwrapResult } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const AgentPage = () => {
  const dispatch = useDispatch();
  const { agent, user } = useSelector((state) => state.user);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [formUpdate] = Form.useForm();
  const [data, setData] = useState(agent);
  const [dataShow, setDataShow] = useState(data);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [userBetData, setUserBetData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (user.role === 'USER') {
      navigate('/trade');
    }
  }, [user, navigate]);

  const fetchData = async () => {
    try {
      const data = await dispatch(getAgentData()).then(unwrapResult);
      setData(data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchBetData = async (username) => {
    try {
      const data = dispatch(getBetData(username)).then(unwrapResult);
      return data;
    } catch (error) {
      message.error(error);
    }
  };

  const onHandleShowBet = async (username) => {
    const data = await fetchBetData(username);
    setUserBetData(data);
    setShowUserBetModal(true);
  };

  const columns = [
    {
      title: t('username'),
      dataIndex: 'username',
      key: 'username',
      render: (text) => text,
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      key: 'balance',
      render: (text) => formatMoney(text),
    },
    {
      title: t('amountSpent'),
      dataIndex: 'amountSpent',
      key: 'amountSpent',
      render: (text) => formatMoney(text),
    },
    {
      title: t('amountDeposit'),
      dataIndex: 'amountDeposit',
      key: 'amountDeposit',
      render: (text) => formatMoney(text),
    },
    {
      title: t('amountWithdraw'),
      dataIndex: 'amountWithdraw',
      key: 'amountWithdraw',
      render: (text) => formatMoney(text),
    },
    {
      title: t('amountWon'),
      dataIndex: 'amountWon',
      key: 'amountWon',
      render: (text) => formatMoney(text),
    },
    {
      title: t('amountLost'),
      dataIndex: 'amountLost',
      key: 'amountLost',
      render: (text) => formatMoney(text),
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('HH:mm DD/MM/YYYY'),
    },
    {
      title: t('action'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={() => {
                setModalUpdate(true);
                formUpdate.setFieldsValue(record);
              }}
            >
              Cập nhật
            </Button>
            <Button
              onClick={() => {
                onHandleShowBet(record.username);
              }}
            >
              {t('history_bet')}
            </Button>
          </Space>
        );
      },
    },
  ];

  const betColumns = [
    {
      title: t('section'),
      dataIndex: 'betSection',
      key: 'betSection',
    },
    {
      title: t('type'),
      dataIndex: 'betType',
      key: 'betType',
      render: (text) => {
        return (
          <Tag className='uppercase w-14 text-center' color={text === 'BUY' ? 'green' : 'red'}>
            {text === 'BUY' ? t('buy') : t('sell')}
          </Tag>
        );
      },
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => formatMoney(text),
    },
    {
      title: t('result'),
      dataIndex: 'betResult',
      key: 'betResult',
      render: (text) => {
        return (
          <Tag className='uppercase w-14 text-center' color={text === 'WIN' ? 'green' : 'red'}>
            {text === 'WIN' ? t('win') : t('lose')}
          </Tag>
        );
      },
    },
    {
      title: t('time'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text).format('HH:mm DD/MM/YYYY'),
    },
  ];

  const onHandleSearch = (value) => {
    const dataSearch = data.filter((item) => {
      return item.username.toLowerCase().includes(value.toLowerCase());
    });
    setDataShow(dataSearch);
  };

  const onHandleUpdate = async (record) => {
    try {
      await dispatch(updateByAgent(record)).then(unwrapResult);
      message.success(t('update_success'));
      setModalUpdate(false);
      const data = await dispatch(getAgentData()).then(unwrapResult);
      setDataShow(data);
    } catch (error) {
      message.error(error);
    }
  };
  return (
    <div className='h-screen'>
      <Modal
        title={t('history_bet')}
        open={showUserBetModal}
        onCancel={() => setShowUserBetModal(false)}
        width={1200}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Table columns={betColumns} dataSource={userBetData} />
      </Modal>
      <div className='flex px-4 pt-6 pb-4'>
        <div className='w-full'>
          <Card className='h-full'>
            <Statistic title={t('totalRef')} value={agent.length} />
          </Card>
        </div>
      </div>

      <div className='p-4'>
        <span className='text-lg font-semibold'>{t('refLink')}: </span>
        <CopyToClipboard
          text={`${window.location.origin}/register?ref=${user.username}`}
          onCopy={(text, r) => {
            if (r) {
              message.success(`${t('copied')}`);
            }
          }}
        >
          <Input value={`${window.location.origin}/register?ref=${user.username}`} />
        </CopyToClipboard>
      </div>

      <span className='text-lg px-4 font-semibold'>{t('statistic_user')}: </span>
      <div className='px-4 pt-2'>
        <div className='py-2'>
          <Input placeholder={t('search_by_username')} onChange={(e) => onHandleSearch(e.target.value)} />
        </div>
        <Table
          scroll={{
            x: 600,
          }}
          rowKey='id'
          columns={columns}
          dataSource={dataShow}
        />
      </div>

      <Modal open={modalUpdate} onCancel={() => setModalUpdate(false)} width={800} footer={null}>
        <Form labelAlign='left' labelCol={{ span: 4 }} form={formUpdate} onFinish={onHandleUpdate}>
          <Form.Item name='id' hidden />
          <Form.Item name='fullname' label={t('fullname')}>
            <Input />
          </Form.Item>
          <Form.Item name='bank_holder' label={t('bank_holder')}>
            <Input />
          </Form.Item>
          <Form.Item name='bank_name' label={t('bank_name')}>
            <Input />
          </Form.Item>
          <Form.Item name='bank_number' label={t('bank_num')}>
            <Input />
          </Form.Item>
          <Form.Item name='id_number' label={t('id_num')}>
            <Input />
          </Form.Item>
          <Form.Item name='isVerified' label={t('status')}>
            <Select>
              <Select.Option value={true}>Đã xác minh</Select.Option>
              <Select.Option value={false}>Chưa xác minh</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button className='w-full' size='large' type='primary' htmlType='submit'>
              {t('update')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AgentPage;
