import axios from 'axios';
import { logout } from '../features/user/userSlice';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

let store;
export const setStore = (newStore) => {
  store = newStore;
};

api.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getState().user;
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
      store.dispatch(logout());
    }
    if (error.response.status === 403) {
      window.location.href = '/';
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export default api;
