import React from 'react';
import { useSelector } from 'react-redux';
import Card from '../components/Card';
import Header from '../components/Header';
import SideUserTransaction from '../components/SideUserTransaction';
import SidePriceItem from '../components/SidePriceItem';
import Chart from './Chart';
import MaintainPage from './Maintain';
import { useTranslation } from 'react-i18next';
const Home = () => {
  const { accessToken, sys } = useSelector((state) => state.user);
  if (accessToken === '') {
    window.location.href = '/login';
  }
  const { t } = useTranslation();
  return sys?.all ? (
    <div className='container-bg '>
      <Header />
      <div className='lg:flex w-screen'>
        {/* <div className='w-screen lg:w-2/12 bg-[#02142b] lg:mx-1 mx-0 mb-10 rounded-md'>
          <Card title={t('trade')}>
            <SidePriceItem />
          </Card>
        </div> */}

        <div className='w-screen'>
          <Chart />
        </div>

        {/* <div className='lg:w-2/12 bg-[#02142b] lg:mx-1 mx-0 mb-4 md:rounded-md rounded-none z-10 min-h-[200px]'>
          <Card title={t('my_trade')}>
            <SideUserTransaction />
          </Card>
        </div> */}
      </div>
    </div>
  ) : (
    <MaintainPage />
  );
};

export default Home;
