export const formatMoney = (money) => {
  var formatter = new Intl.NumberFormat('vi-VN', {
    minimumFractionDigits: 0,
  });
  return formatter.format(money?.toFixed(0));
};

export const formatMoneyInput = (money) => {
  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  });
  return formatter.format(money);
};

export const removeDotComma = (string) => {
  function switcher(match) {
    return match === ',' ? '' : '';
  }

  return string.replaceAll(/\.|\,/g, switcher);
};
